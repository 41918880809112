@property --color {
    syntax: "<color>";
    initial-value: white;
    inherits: false;
}

.App {
    height: 100%;
    display: grid;
    place-items: center;
}

.sr-only {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}
