.InnerGame {
  width: 100%;
  display: flex;
  flex-direction: column;

  & > .main {
    flex: 1;
  }

  .pageControls {
    position: fixed;
    display: grid;
    padding: 10px;
    gap: 10px;
    //grid-template-columns: 1fr 1fr;
    top: 0;
    right: 0;
  }
}

.door-anim {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  svg {
    width: auto !important;
  }
}
