.NavBar {
  display: flex;
  flex-direction: row;
  background-color: blue;
  height: 50px;
  color: white;

    & > a {
        flex: 1;
        text-align: center;
        padding: 10px;
        border: 1px solid black;

      &.active {
                background-color: darkcyan;
            }
    }
}
