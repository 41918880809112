/*@import url('https://fonts.googleapis.com/css2?family=Passion+One:wght@400;700;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');*/

// loop font-face for Roboto for weight and style
@each $weight in (100, 300, 400, 500, 700, 900) {
  @each $style in (normal, italic) {
    @font-face {
      font-family: "Roboto";
      font-style: $style;
      font-weight: $weight;
      src: url("./Game/assets/fonts/Roboto/Roboto-#{$weight}#{$style}.ttf")
        format("truetype");
    }
  }
}

// loop font-face for Passion One for weight
@each $weight in (400, 700, 900) {
  @font-face {
    font-family: "Passion One";
    font-style: normal;
    font-weight: $weight;
    src: url("./Game/assets/fonts/Passion_One/PassionOne-#{$weight}.ttf")
      format("truetype");
  }
}

html {
  font: -apple-system-body;
}

body {
  margin: 0;
  --font: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  --emphasis-font: "Passion One", var(--font);
  font-family: var(--font);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

.emphasis {
  font-family: var(--emphasis-font);
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.5em;
}
