.Game {
  outline: 1px solid black;
  min-width: 320px;
  min-height: 568px;
  width: 320px;
  height: 568px;
  position: relative;
  container-type: size;

  display: flex;
  flex-direction: column;
  user-select: none;

  &.full-screen {
    width: 100%;
    height: 100%;
    min-width: 0;
    min-height: 0;
    outline: none;
  }

  & > .main {
    width: 100%;
    flex: 1;
    align-self: stretch;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    align-items: stretch;
    position: relative;
    overflow: hidden;
    font-size: 1rem;
    //font-size: min(1rem, 28px);
    font-size: clamp(1rem, 3cqmax, 1.35rem);
  }
}

.page {
  width: 100%;
  height: 100%;
}
